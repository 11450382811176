<template>
    <div class="form_grp">
        <div class="group_item">
            <div class="input_label">
                Send Portal Message
                <emoji-picker :onSelectEmoji="insertEmoji" classes="subject-emoji-picker" :ref="`emoji-${uniqueId}`" v-click-outside="hideEmoji" />
                <div class="dropdown dropdown-personalize mr-3" @click="smsOption = !smsOption" v-click-outside="hidePersonalize">
                    <i class="fas fa-user-alt"></i>
                    <div class="drp_wrapper" v-if="smsOption" :class="{ active : smsOption }">
                        <ul>
                            <li v-for="(personalize, p) of defaultPersonalize" @click="insertPersonalize(personalize)" :key="p">{{ personalize }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="field_wpr" :class="{ 'has-error': hasTextError }">
                <textarea :placeholder="placeholder" :id="uniqueId" v-model="messageText"></textarea>
            </div>
            <span class="text-danger" v-show="hasTextError">{{ textErrorMessage }}</span>
            <span class="letter_count">{{ messageText.length }}</span>
        </div>
    </div>

    <label :for="`message_attachment-${uniqueId}`" class="switch_option capsule_btn">
        <h5>Attach Media</h5>
        <input type="checkbox" :id="`message_attachment-${uniqueId}`" v-model="attachment" hidden>
        <div><span></span></div>
    </label>
    <div v-if="attachment" class="upload_image">
        <label :for="`portal-attachment-${uniqueId}`">
            <input type="file" @change="fileSelected($event)" :id="`portal-attachment-${uniqueId}`" hidden accept="image/jpg, image/jpeg, image/png, audio/mp3, video/mp4, application/pdf">
            <img src="@/assets/images/image2.png">
            <h4>Click to Upload</h4>
        </label>
        <span class="text-danger" v-show="hasMediaError">{{ mediaErrorMessage }}</span>
        <div class="sms-media" v-if="media.length > 0">
            <div class="chatbox-media" v-for="(image, key) in media" :key="key">
                <span v-html="getFileIconByUrl(image.url ? image.url : image, uniqueId, image.type ? image.type : '' )"></span>
                <a href="javascript:void(0);" class="text-danger remove-media" @click="removeMedia(key)"><i class="fa fa-times"></i></a>
            </div>
        </div>
    </div>

    <label for="smschedule" class="switch_option capsule_btn">
        <h5>Schedule Portal Message</h5>
        <input type="checkbox" :true-value="1" :false-value="0" v-model="messageSchedule" id="smschedule" hidden>
        <div><span></span></div>
    </label>
    <div v-if="messageSchedule" class="schedule_sms">
        <h3 class="sub_header">Schedule Portal Message</h3>
        <div class="edit_section">
            <schedule-tab v-model="schedule" ref="portal-chat-schedule" />
            <div class="action_wpr mt-2">
                <button :disabled="scheduleLoader" type="button" class="btn save_btn" @click="handleScheduleMessage"><i class="fa fa-spinner fa-spin" v-if="scheduleLoader"></i> {{ scheduleLoader ? 'Scheduling' : 'Schedule' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { uuid } from 'vue-uuid'
    import { mapState, mapActions } from 'vuex'

    const ScheduleTab = defineAsyncComponent(() => import('@/components/ScheduleTab'))
    const EmojiPicker = defineAsyncComponent(() => import('@/components/EmojiPicker'))

    import constants from '@/utils/Constant'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'

    export default {
        name: "Portal Message",

        data () {
            return {
                uniqueId: 'message-'+uuid.v4(),
                smsOption: false,
                messageSchedule: false,
                defaultPersonalize: [],
                schedule: {},
                hasTextError: false,
                textErrorMessage: '',

                messageText: '',
                attachment: false,
                media: [],
                hasMediaError: false,
                mediaErrorMessage: '',
            };
        },

        props:{
            contacts: [String, Number],
            placeholder: {
                type: String,
                default: 'Type message',
            },
            personalize: {
                type: [Object, Array],
                default: () => [],
            },
            successHandler: Function,
        },

        watch: {

        },

        components:{
            ScheduleTab,
            EmojiPicker,
        },

        computed: {
            ...mapState({
                scheduleLoader: state => state.contactModule.scheduleMessageLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.defaultPersonalize = constants.personalize.concat(vm.personalize);
        },

        methods: {
            ...mapActions({
                sendMessage: 'contactModule/sendMessage',
                scheduleMessage: 'contactModule/scheduleMessage',
                fileUpload: 'commonModule/fileUpload'
            }),

            insertEmoji (emoji) {
                const vm = this;
                const textarea    = document.getElementById(vm.uniqueId);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = emoji;

                vm.messageText    = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            insertPersonalize (personalize) {
                const vm = this;
                const textarea    = document.getElementById(vm.uniqueId);
                const caretPos    = textarea.selectionStart;
                const textAreaTxt = textarea.value;
                const txtToAdd    = `{{ ${ personalize.toLowerCase().replaceAll(' ', '_') } }}`;

                vm.messageText = textAreaTxt.substring(0, caretPos) + ' ' +  txtToAdd + ' '  + textAreaTxt.substring(caretPos);
            },

            hideEmoji () {
                const vm = this;
                const emoji = vm.$refs[`emoji-${vm.uniqueId}`];

                if (emoji && emoji.toggle) {
                    emoji.toggle = false;
                }
            },

            hidePersonalize () {
                const vm = this;

                vm.smsOption = false;
            },

            handleSendMessage () {
                const vm = this;

                if (!vm.validate()) {
                    return;
                }

                const params = {
                    message: vm.messageText,
                    media: vm.media ? vm.media : [],
                    receiver_id: vm.contacts,
                    source: 'bulk-action',
                }

                vm.sendMessage(params).then((result) => {
                    if (result && vm.successHandler) {
                        vm.successHandler();
                    }
                });
            },

            handleScheduleMessage () {
                const vm  = this;

                if (!vm.validate()) {
                    return;
                }

                const params = {
                    receiver_id: vm.contacts,
                    message: vm.messageText,
                    media: vm.media ? vm.media : [],
                    delay: vm.schedule,
                };

                vm.scheduleMessage(params).then((result) => {
                    if (result && vm.successHandler) {
                        vm.successHandler();
                    }
                });
            },

            validate () {
                const vm  = this;

                if (vm.messageText) {
                    vm.hasTextError     = false;
                } else {
                    vm.hasTextError     = true;
                    vm.textErrorMessage = 'The message field is required.';
                }

                if (vm.attachment) {
                    if (vm.media && vm.media.length) {
                        vm.hasMediaError    = false;
                        vm.hasTextError     = false;
                        vm.textErrorMessage = '';
                    } else {
                        vm.hasMediaError = true;
                    }
                } else {
                    vm.hasMediaError = false;
                    // vm.mediaErrorMessage = 'The media field is required.'
                }

                return (vm.hasTextError || vm.hasMediaError) ? false : true;
            },

            fileSelected (event) {
                const vm = this;
                const file = event.target.files[0];
                const fileSize = (file.size / 1024 / 1024).toFixed(1);

                if (fileSize > 4.5) {
                    vm.hasMediaError     = true;
                    vm.mediaErrorMessage = `File is too big (${fileSize} MB). Max filesize: 4.5 MB.`;
                } else {
                    vm.hasMediaError     = false;
                    vm.mediaErrorMessage = '';

                    let formData = new FormData();
                    formData.append('file', file);

                    vm.fileUpload(formData).then((file) => {
                        vm.media = [];
                        vm.media.push(file.url);
                    });
                }
            },

            getFileIconByUrl (image, uniqueId, contentType = '') {
                return Helper.getFileIconByUrl(image, uniqueId, contentType);
            },

            removeMedia (index) {
                const vm = this;

                vm.media.splice(index, 1);
            },
        },
    }
</script>

<style scoped>
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
        margin-right: 5px;
    }

    :deep(.emoji-picker.subject-emoji-picker) {
        margin: 0 0 0 auto;
    }

    .input_label {
        position: relative;
    }

    :deep(.subject-emoji-picker) {
        position: absolute;
        right: 0;
    }

    :deep(.subject-emoji-picker .dropdown-toggle) {
        margin-right: 40px;
    }

    .dropdown-personalize {
        position: absolute;
        right: 0;
    }
</style>
